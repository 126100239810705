var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("SpainhnNavigationBreadcrumb", {
        attrs: { "navigation-links": _vm.links },
      }),
      _c("div", { staticClass: "container-fluid px-md-5 px-1" }, [
        _c("div", { staticClass: "d-flex justify-content-around pt-4 mb-3" }, [
          _c(
            "div",
            { staticClass: "d-flex", staticStyle: { width: "100%" } },
            [
              _c(
                "icon-base",
                { attrs: { width: 55, height: 55, iconColor: "rgb(0,0,0)" } },
                [_c("icon-casttle")],
                1
              ),
              _c(
                "div",
                { staticClass: "ms-3 h-100 d-flex flex-column text-start" },
                [
                  _c(
                    "p",
                    { staticClass: "mb-0 mt-auto spainhn-subtitle-2-black" },
                    [_vm._v(_vm._s(_vm.nombreDeMonumentoPorIdioma))]
                  ),
                  _c(
                    "small",
                    { staticClass: "mb-0 mt-auto spainhn-subtitle-4-black" },
                    [_vm._v(_vm._s(_vm.address))]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "container mb-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column pt-3 ps-3 pe-3 col-12" },
              [
                _c("h5", { staticClass: "mb-0" }, [
                  _c("strong", [
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("default.MENSAJE_SERVICIOS_ESPACIOS_HEADER", {
                            msg: _vm.nombreDeMonumentoPorIdioma,
                          })
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("p", { staticClass: "mb-0" }, [
                  _c("strong", [
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("default.MENSAJE_SERVICIOS_ESPACIOS_SUB")
                        ) +
                          " " +
                          _vm._s(_vm.nombreDeMonumentoPorIdioma)
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "h-100 pt-5 pb-5 spainhn-bg-gris-cajas ps-3 pe-0 spainhn-text d-flex flex-column",
                    staticStyle: { "border-radius": "10px" },
                  },
                  [
                    _c("span", {
                      staticClass:
                        "text-start px-3 text-wrap mx-auto text-columns",
                      domProps: { innerHTML: _vm._s(_vm.descripcion) },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _c("p", { staticClass: "spainhn-small-text" }, [
              _vm._v(_vm._s(_vm.$t("default.SERVICES_CONTACT_DETAILS")) + "."),
            ]),
            _c(
              "mdb-btn",
              {
                staticClass: "spainhn-button mb-3",
                attrs: { color: "indigo" },
                on: { click: () => _vm.$router.push({ name: "contact" }) },
              },
              [_vm._v(_vm._s(_vm.$t("default.SPAINHN_BUTTON_CONTACT")))]
            ),
          ],
          1
        ),
      ]),
      _c("components-cloud"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }